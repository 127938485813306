import { ChangeEvent, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import {
  useGqlMajorByNameLazyQuery,
  useGqlSearchMajorsLazyQuery,
} from 'generated/graphql'
import { useOutSideClick } from 'hooks/useOutSideClick'
import KeywordList from '../searchKeyword/keywordList/KeywordList'
import { TKeywordList } from '../searchKeyword/types/keywordListType'

const InputSearchMajor = () => {
  const { register, setValue } = useFormContext()

  const outSideWrapperRef = useRef<HTMLDivElement>(null)
  const [isShowInputSearch, setIsShowInputSearch] = useState<boolean>(false)
  const [majorInput, setMajorInput] = useState<string>('')

  const [searchMajorList, { data }] = useGqlSearchMajorsLazyQuery()
  const [createMajorName] = useGqlMajorByNameLazyQuery()

  const [debouncedCallback] = useDebouncedCallback((keyword) => {
    setMajorInput(keyword)
    handleKeywordQuery && handleKeywordQuery(keyword)
  }, 300)

  const handleChangeKeyword = (value: string) => {
    setIsShowInputSearch(!!value)
    debouncedCallback(value)
  }

  const handleChangeMajorInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    e.target.value = value
    handleChangeKeyword(value)
  }

  const handleSelectKeywordOption = async (selectOptionValue: TKeywordList) => {
    if (!selectOptionValue.id) {
      const { data } = await createMajorName({
        variables: {
          name: selectOptionValue.name,
        },
      })
      setValue('schoolMajor', {
        id: data?.majorByName.id || '',
        name: data?.majorByName.name || '',
      })
    } else {
      setValue('schoolMajor', {
        id: selectOptionValue.id || '',
        name: selectOptionValue.name,
      })
    }
  }

  const handleSelectKeywordItem = (selectValue: TKeywordList) => {
    handleSelectKeywordOption(selectValue)
    setIsShowInputSearch(false)
  }
  const handleOutsideClick = () => {
    setIsShowInputSearch(false)
  }

  useOutSideClick({
    ref: outSideWrapperRef,
    isActive: isShowInputSearch,
    onOutsideClick: handleOutsideClick,
  })

  const handleKeywordQuery = async (keyword: string) => {
    if (keyword) {
      await searchMajorList({
        variables: {
          filterBy: {
            query: keyword,
          },
        },
      })
    }
  }

  const majorList = data?.searchMajors.map((item) => {
    return {
      id: item.id,
      name: item.name,
    }
  })

  return (
    <StyledWrapper ref={outSideWrapperRef}>
      <div className="input-major-wrapper">
        <input
          type="text"
          placeholder="전공"
          autoComplete="off"
          className="input-major"
          {...register('schoolMajor.name')}
          onChange={handleChangeMajorInput}
        />
      </div>
      {isShowInputSearch && (
        <KeywordList
          keyword={majorInput}
          keywordListData={majorList || []}
          onSelectKeywordItem={handleSelectKeywordItem}
        />
      )}
    </StyledWrapper>
  )
}
export default InputSearchMajor

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    > .input-major-wrapper {
      position: relative;
      > .input-major {
        width: 100%;
        height: 50px;
        padding: 0 16px;
        border-radius: 8px;
        border: solid 1px #dddddd;
        font-size: 16px;
        &:focus {
          border: 1px solid ${theme.color.primary_2};
          outline: none;
        }
        &.error {
          color: ${theme.color.error_1};
          border: solid 1px ${theme.color.error_1};
        }
        &::placeholder {
          color: ${theme.color.text_3};
        }
      }
    }
  `}
`
