import { useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'
import {
  SchoolType,
  useGqlSchoolByNameLazyQuery,
  useGqlSearchSchoolsLazyQuery,
  UserEducation,
} from 'generated/graphql'
import InputSearchKeyword from '@app.feature/signup/signup/components/inputFields/searchKeyword/InputSearchKeyword'
import { TKeywordList } from '@app.feature/signup/signup/components/inputFields/searchKeyword/types/keywordListType'
import SelectBox from '@app.feature/signup/signup/components/inputFields/select/SelectBox'
import SelectDropdown from '@app.feature/signup/signup/components/inputFields/select/SelectDropdown'
import { SCHOOL_TYPES } from '@app.feature/signup/signup/constant/schoolTypeConstant'
import FormLabel from '@app.feature/signup/signup/desktop/components/FormLabel'
import { useOutSideClick } from 'hooks/useOutSideClick'
import InputSearchMajor from '../inputFields/searchMajor/InputSearchMajor'

const FieldSchool = () => {
  const { register, setValue } = useFormContext()
  const selectRef = useRef<HTMLDivElement>(null)

  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const handleSelectBoxOpen = () => {
    setIsOpenSelect(!isOpenSelect)
  }
  const handleOutsideClick = () => {
    setIsOpenSelect(false)
  }
  useOutSideClick({
    ref: selectRef,
    isActive: isOpenSelect,
    onOutsideClick: handleOutsideClick,
  })

  const getHighUniType = (selectedType: string) => {
    return selectedType === UserEducation.HIGH_SCHOOL_GRADUATE
      ? SchoolType.HIGH_SCHOOL
      : SchoolType.UNIVERSITY
  }

  const selectedSchoolType = useWatch({ name: 'schoolType' })
  const selectedHighUnivType = getHighUniType(selectedSchoolType)

  const handleSelectSchoolType = (selectedType: string) => {
    const newSelectedHighUnivType = getHighUniType(selectedType)
    if (newSelectedHighUnivType !== selectedHighUnivType) {
      setValue(
        'school',
        {
          id: '',
          name: '',
          isChanged: false,
        },
        { shouldValidate: true },
      )
      setValue('schoolMajor', {
        id: '',
        name: '',
      })
    }
    setValue('schoolType', selectedType, { shouldValidate: true })
    setIsOpenSelect(false)
  }

  const [searchSchoolName, { data: schoolList }] =
    useGqlSearchSchoolsLazyQuery()

  const [schoolByName] = useGqlSchoolByNameLazyQuery()

  const handleSelectKeywordOption = async (selectOptionValue: TKeywordList) => {
    if (!selectOptionValue.id) {
      const { data } = await schoolByName({
        variables: {
          type: selectedHighUnivType,
          name: selectOptionValue.name,
        },
      })
      setValue(
        'school',
        {
          id: data?.schoolByName.id || '',
          name: data?.schoolByName.name || '',
          isChanged: false,
        },
        { shouldValidate: true },
      )
    } else {
      setValue(
        'school',
        {
          id: selectOptionValue.id || '',
          name: selectOptionValue.name,
          isChanged: false,
        },
        { shouldValidate: true },
      )
    }
  }

  const handleKeywordQuery = async (keyword: string) => {
    if (keyword) {
      await searchSchoolName({
        variables: {
          filterBy: {
            type: selectedHighUnivType,
            query: keyword,
          },
        },
      })
    }
  }

  const selectSchoolTypeLabel =
    SCHOOL_TYPES.find((option) => option.value === selectedSchoolType)?.label ||
    ''

  const keywordList = schoolList?.searchSchools.map((school) => {
    return {
      id: school.id,
      name: school.name,
    }
  })

  const handleChangeEditState = () => {
    setValue('school.isChanged', true)
  }

  return (
    <StyledWrapper>
      <FormLabel label="최종학력" />
      <div className="field-school-container">
        <div className="selectBox-container" ref={selectRef}>
          <SelectBox
            onClick={handleSelectBoxOpen}
            selectedValue={selectSchoolTypeLabel}
          />
          {isOpenSelect && (
            <SelectDropdown
              options={SCHOOL_TYPES}
              onClickOption={handleSelectSchoolType}
            />
          )}
        </div>

        <InputSearchKeyword
          placeholder="학교명"
          keywordList={keywordList}
          onSelectKeywordOption={handleSelectKeywordOption}
          onKeywordQuery={handleKeywordQuery}
          {...register('school.name', {
            onChange: handleChangeEditState,
          })}
        />
      </div>
      {selectedSchoolType &&
        selectedSchoolType !== UserEducation.HIGH_SCHOOL_GRADUATE && (
          <InputSearchMajor />
        )}
    </StyledWrapper>
  )
}
export default FieldSchool

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: 9px;
    .field-school-container {
      display: grid;
      grid-template-columns: ${!theme.isMobile && '160px auto'};
      gap: ${theme.isMobile ? '10px' : '5px'};
      .selectBox-container {
        position: relative;
        cursor: pointer;
      }
    }
    p {
      margin: 0 !important;
    }
    ul {
      margin: 0;
    }
  `}
`
